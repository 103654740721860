import React  from 'react';
import { Link } from "gatsby"

import { Menu } from 'antd';
import "antd/dist/antd.css";
const { SubMenu } = Menu;

const menuStyle = {
  backgroundColor: "ghostwhite",
}

const Navigation = () => {
  return (
    <div style={{marginRight: "auto", marginLeft: "auto"}}>
    <header className="global-header" > 

      <Menu mode="horizontal" style={menuStyle}>
        <Menu.Item key="home" style={{float: 'left'}}>
          <Link to="/"><strong>HOME</strong></Link>
        </Menu.Item>
        <Menu.Item key="about" className="menu-item">
          <Link to="/about"><strong>当院について</strong></Link>
        </Menu.Item>
        <Menu.Item key="guide" >
          <Link to="/guide"><strong>受診案内</strong></Link>
        </Menu.Item>
        <Menu.Item key="online">
          <Link to="/online"><strong>スマホ診療</strong></Link>
        </Menu.Item>
        <Menu.Item key="access">
          <Link to="/access"><strong>アクセス</strong></Link>
        </Menu.Item>
        <Menu.Item key="faq">
          <Link to="/faq"><strong>よくある質問</strong></Link>
        </Menu.Item>
        <SubMenu key="submenu" title="その他" style={{fontWeight: "700", marginLeft: 'auto'}}>
          <Menu.Item key="posts">
            <Link to="/posts"><strong>お知らせ一覧</strong></Link>
          </Menu.Item>
          <Menu.Item key="Bulletins">
            <Link to="/bulletins"><strong>院内掲示一覧</strong></Link>
          </Menu.Item>
          <Menu.Item key="cases">
            <Link to="/cases"><strong>架空の症例集</strong></Link>
          </Menu.Item>
          <Menu.Item key="tools">
            <Link to="/tools"><strong>ツール</strong></Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </header>
    </div>
  )
}

export default Navigation

