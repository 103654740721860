import React from 'react'
// import { Card } from 'antd';
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { Typography } from 'antd';
import { iconStyle, buttonStyle } from '../../../styles/custom'


const onCall = (event) => {
  event.preventDefault();
  window.location.href='tel:022-369-3691';
}

const Phone = ({testid}) => {
  return (
        <button 
          style={buttonStyle}
          className="button" onClick={onCall}>
          <FontAwesomeIcon style={iconStyle} icon={faPhone} /> 
          電話
        </button>
  )
}

export default Phone
