import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { ListArticle } from './common/lists'
import { Card } from 'antd';
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Typography } from 'antd';

const { Text } = Typography;

export const GET_RECENT_POSTS = graphql`
      query {
        allMdx(
          filter: {frontmatter: {tags: {eq: "お知らせ"}}}, 
          sort: { fields: [frontmatter___date], order: DESC }, 
          limit: 3
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY/MM/DD")
              title
              description
            }
          }
        }
      }
    `

const RecentPosts = ({test_id}) => {
  const data = useStaticQuery(GET_RECENT_POSTS)
  const posts = data.allMdx ? data.allMdx.nodes : []

  if (posts.length === 0) {
    // console.log("no posts")
    // console.log(data)
    return (
      <div>
      {/* <div data-testid="recent-posts" className="text-center"> */}
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </div>
    )
  } else {
    // console.log("posts", data)
  }

  return (
    <div data-testid={test_id} className="card">
      <Card title={<Text strong><FontAwesomeIcon  icon={faInfo} /> 最近のお知らせ</Text>}
        size="small" 
        bordered={true} 
        extra={<Link to={"/posts"} itemProp="url"> 一覧</Link>} 
        style={{marginTop: 20, backgroundColor: 'cornsilk', border: 3 , borderRadius: 6}}
        headStyle={{backgroundColor: 'wheat', border: 0, borderRadius: 6 }}
       >
          <ListArticle articles={posts} testid="recent-posts" />
      </Card>
    </div>

  )
}

export default RecentPosts

