import styled from 'styled-components';

const StyledCalendar = styled.div`
  /* ~~~ container styles ~~~ */
  margin: auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  /* ~~~ day grid styles ~~~ */
  .react-calendar {
    margin: auto;
  }
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 

    .react-calendar__tile {
      max-width: initial !important;
    }
  }
  /* ~~~ day grid styles ~~~ */
  .react-calendar .react-calendar__tile:nth-child(7n) {
    color: #00F;
  }

`;

export default StyledCalendar
