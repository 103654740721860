import * as React from "react"
import "antd/dist/antd.css";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import CurrentInfo from '../current-info';
import RecentPosts from '../recent-posts';
import TableOfContent from "../common/toc"

const LeftSideBar = ({toc}) => {
  return (
    <>
      <CurrentInfo />
      <RecentPosts />
      {toc && (
        <TableOfContent items={toc.items}/>
      )}
      { /* breakpoints.smartphone ? (
      ) : (
        null 
      ) */}
    </>
  )
}

export default LeftSideBar

