

// 祝日の日付と同じ表記に変換
export const getFormatDate = (date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}

export const getFirstDayOfNextMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

export const getLastDayOfThisMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}
export const getLastDayOfNextMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 2, 0)
}
