import React, { useState, useEffect } from 'react';

import moment from 'moment'
require('moment/locale/ja');
moment.locale('ja');

const DateTime = ({test_id}) => {
  const [now, setNow] = useState(moment())
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(_ => moment());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div data-testid={test_id} className="text-center">
      {now.format("YYYY年M月D日(dd) HH:mm")}
    </div>
  )
}

export default DateTime;
