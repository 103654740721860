// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import './src/styles/reset.css'
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
// import "./src/style.css"
import './src/styles/global.css'

// Highlighting for code blocks
// import "prismjs/themes/prism.css"
// スマートホン用設定
// import './src/styles/mobile.css'

import Amplify from "aws-amplify"
import awsConfig from "./src/aws-exports"
Amplify.configure(awsConfig)
