import styled from "styled-components"

const iconStyle = {
  padding: '2',
  fontSize: '20'
}

const buttonStyle = {
  color: '#fff',
  backgroundColor: 'tomato',
  border: '0',
  borderRadius: '10px',
  padding: '8px 8px',
  margin: '6px',
  marginBottom: '40px',
  left: '1rem'
}

const StyledH3 = styled.h3`
  color: darkblue;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`

export { iconStyle, buttonStyle, StyledH3 }

