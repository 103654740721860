import React from 'react'
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { iconStyle, buttonStyle } from '../../../styles/custom'

// const { Text } = Typography;
// const { Meta } = Card;

// const buttonStyle = {
//   color: '#fff',
//   backgroundColor: '#eb6100',
//   border: '0',
//   borderRadius: '10px',
//   padding: '8px 8px',
//   margin: '10px',
//   left: '1rem'
// }

const onClick = (event) => {
  event.preventDefault();
  window.location.href='https://goo.gl/maps/wfzN7QbHRps';
}

const Map = ({content, style, testid}) => {
  return (
        <button 
          style={style ? style : buttonStyle}
          className="button" onClick={onClick}>
          <FontAwesomeIcon style={iconStyle} icon={faMap} /> 
          {content ? content : "マップ"}
        </button>
  )
}

export default Map

