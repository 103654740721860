import * as React from "react"

const online = {
  columns: [
    {
      title: '項目',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '診療点数/料金',
      dataIndex: 'point',
      key: 'point',
      align: 'left'
    }
  ],
  dataSource: [
    {
      key: '1',
      name: '再診料(電話再診) ',
      point: "73点",
    },
    {
      key: '2',
      name: '処方せん料',
      point: "約68点",
    },
    {
      key: '3',
      name: '明細書発行体制加算',
      point: "1点",
    },
    {
      key: '4',
      name: '遠隔診療利用料',
      point: "2000円",
    },
    {
      key: '5',
      name: '配送手数料',
      point: "550円",
    },
  ]
}
const premeeting = {
  columns: [
    {
      title: '項目',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '自費料金',
      dataIndex: 'price',
      key: 'price',
      align: 'left'
    }
  ],
  dataSource: [
    {
      key: '1',
      name: '受診前相談料',
      price: "3000円",
    },
    {
      key: '2',
      name: '遠隔診療利用料',
      price: "2000円",
    },
    {
      key: '3',
      name: '合計',
      price: "5000円",
    },
  ]
}

const tools = {
  pension: {
    columns : [
      {
        title: '判定平均',
        dataIndex: 'judgement',
        key: 'judgement',
      },
      {
        title: '(5)',
        dataIndex: 'five',
        key: 'five',
      },
      {
        title: '(4)',
        dataIndex: 'four',
        key: 'four',
      },
      {
        title: '(3)',
        dataIndex: 'three',
        key: 'three',
      },
      {
        title: '(2)',
        dataIndex: 'two',
        key: 'two',
      },
      {
        title: '(1)',
        dataIndex: 'one',
        key: 'one',
      },
    ],
    dataSource: [
      {
        key: '1',
        judgement: '3.5以上',
        five: "1級",
        four: "1級または2級",
        three: "",
        two: "",
        one: ""
      },
      {
        key: '2',
        judgement: '3.0以上3.5未満',
        five: "1級または2級",
        four: "2級",
        three: "2級",
        two: "",
        one: ""
      },
      {
        key: '3',
        judgement: '2.5以上3.0未満',
        five: "",
        four: "2級",
        three: "2級または3級",
        two: "",
        one: ""
      },
      {
        key: '4',
        judgement: '2.0以上2.5未満',
        five: "",
        four: "2級",
        three: "2級または3級",
        two: "3級または3級非該当",
        one: ""
      },
      {
        key: '5',
        judgement: '1.5以上2.0未満',
        five: "",
        four: "",
        three: "3級",
        two: "3級または3級非該当",
        one: ""
      },
      {
        key: '6',
        judgement: '1.5未満',
        five: "",
        four: "",
        three: "",
        two: "3級非該当",
        one: "3級非該当"
      },
    ]
  },
  bmi: {
    columns : [
      {
        title: 'BMI',
        dataIndex: 'bmi',
        key: 'bmi',
      },
      {
        title: '判定',
        dataIndex: 'evaluation',
        key: 'evaluation',
        align: 'right'
      }
    ],
    dataSource: [
      {
        key: '1',
        bmi: '18.5未満',
        evaluation: "低体重"
      },
      {
        key: '2',
        bmi: '18.5〜25.0未満',
        evaluation: "標準体重"
      },
      {
        key: '3',
        bmi: '25.0〜30.0未満',
        evaluation: "肥満(1度)"
      },
      {
        key: '4',
        bmi: '30.0〜35.0未満',
        evaluation: "肥満(2度)"
      },
      {
        key: '5',
        bmi: '35.0〜40.0未満',
        evaluation: "肥満(3度)"
      },
      {
        key: '6',
        bmi: '40.0以上',
        evaluation: "肥満(4度)"
      },
    ]
  }
}


const price = {
  ownExpense: {
    columns : [
      {
        title: '自費診療の種類',
        dataIndex: 'kind',
        key: 'kind',
      },
      {
        title: '料金(税込)',
        dataIndex: 'price',
        key: 'price',
        align: 'right'
      },
    ],
    dataSource: [
      {
        key: '1',
        kind: '診断書(当院書式)',
        price: "3,520円/1通"
      },
      {
        key: '2',
        kind: '診断書(書式が指定されたもの)',
        price: "6,000円/1通"
      },
      {
        key: '3',
        kind: '診断書(英文)',
        price: "7,700円/1通"
      },
      {
        key: '4',
        kind: '英文薬剤証明書',
        price: "3,900円/1頁"
      },
      {
        key: '5',
        kind: '自立支援診断書',
        price: "3,000円/1通"
      },
      {
        key: '6',
        kind: '福祉手帳診断書',
        price: "6,090円/1通"
      },
      {
        key: '7',
        kind: '年金診断書',
        price: "8,520円/1通"
      },
      {
        key: '8',
        kind: '年金診断書再審査意見書',
        price: "5,500円/1通"
      },
      {
        key: '9',
        kind: '受診状況等証明書',
        price: "5,800円/1通"
      },
      {
        key: '10',
        kind: '就労可否証明書',
        price: "1,300円/1通"
      },
      {
        key: '11',
        kind: '主治医意見書',
        price: "3,600円/1通"
      },
      {
        key: '12',
        kind: '保険会社用証明書(自賠責・生命保険等) ',
        price: "6,600円/1通"
      },
      {
        key: '13',
        kind: '診療情報提供書(産業医など保険医療機関外宛て)',
        price: "5,000円/1通"
      },
      {
        key: '14',
        kind: '成年後見制度診断書',
        price: "8,800円/1通"
      },
      {
        key: '15',
        kind: '成年後見制度鑑定書',
        price: "50,000円/1通"
      },
      {
        key: '16',
        kind: '診療録開示基本料',
        price: "3,100円/1回"
      },
      {
        key: '17',
        kind: '診療録複写料',
        price: "70円/1枚"
      },
      {
        key: '18',
        kind: '診療録開示のさいの説明料',
        price: "6,000円/30分ごと"
      },
      {
        key: '19',
        kind: '遠隔診療利用料',
        price: "2,000円/1回"
      },
      {
        key: '20',
        kind: '配送手数料',
        price: "550円/1回"
      },
      {
        key: '21',
        kind: '時間外診療(受付終了時間後の受診)',
        price: "1,100円/1回"
      },
      {
        key: '22',
        kind: '受診前相談料(遠隔診療のみ)',
        price: "3,000円/30分"
      },
      {
        key: '23',
        kind: '医師面談料(本人が同席しない場合)',
        price: "3,000円/30分ごと"
      },
      {
        key: '24',
        kind: 'インフルエンザ予防接種',
        price: "3,300円"
      },
    ]
  }
}

const schedule = {
  regular: {
    columns : [
      {
        title: '曜日',
        dataIndex: 'weekday',
        key: 'weekday',
      },
      {
        title: '午前',
        dataIndex: 'am',
        key: 'am',
        align: 'left'
      },
      {
        title: '午後',
        dataIndex: 'pm',
        key: 'pm',
        align: 'left'
      },
    ],
    dataSource: [
      {
        key: 'monday',
        weekday: '月曜',
        am: "10:00〜12:30",
        pm: "14:00〜18:00",
      },
      {
        key: 'tuesday',
        weekday: '火曜',
        am: "10:00〜12:30",
        pm: "14:00〜18:00",
      },
      {
        key: 'wendnesday',
        weekday: '水曜',
        am: "休診",
        pm: "休診",
      },
      {
        key: 'thursday',
        weekday: '木曜',
        am: "10:00〜12:30",
        pm: "14:00〜18:00",
      },
      {
        key: 'friday',
        weekday: '金曜',
        am: "10:00〜12:30",
        pm: "再来診療なし(新患とスマホ診療のみ)",
      },
      {
        key: 'saturday',
        weekday: '土曜',
        am: "10:00〜12:30",
        pm: "14:00〜16:00",
      },
      {
        key: 'sunday',
        weekday: '日曜',
        am: "休診",
        pm: "休診",
      },
    ]
  },
  newPatient: {
    columns: [
      {
        title: '曜日',
        dataIndex: 'weekday',
        key: 'weekday',
      },
      {
        title: '新患枠(1人分)',
        dataIndex: 'frame',
        key: 'frame',
        align: 'right'
      }
    ],
    dataSource: [
      {
        key: '1',
        weekday: '月曜',
        frame: "15:30"
      },
      {
        key: '2',
        weekday: '火曜',
        frame: "15:30"
      },
      {
        key: '3',
        weekday: '水曜(休診日)',
        frame: "なし"
      },
      {
        key: '4',
        weekday: '木曜',
        frame: "15:30"
      },
      {
        key: '5',
        weekday: '金曜',
        frame: "午後に複数枠あり"
      },
      {
        key: '6',
        weekday: '土曜',
        frame: "なし"
      },
      {
        key: '7',
        weekday: '日曜(休診日)',
        frame: "なし"
      },
    ]
  }
}

const parking = {
  columns: [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return record.url ? (<a href={record.url}> {text} </a>) : <span>{text}</span>
      }
    },
    {
      title: '場所',
      dataIndex: 'place',
      key: 'place',
      align: 'left'
    },
    {
      title: '駐車料金',
      dataIndex: 'price',
      key: 'price',
      align: 'right'
    }
  ],
  dataSource: [
    {
      key: '1',
      name: 'ダイヤパーク新寺2丁目',
      place: "仙台市若林区新寺２丁目２−１６",
      price: "200円/1時間",
      url: "https://www.google.com/maps/place/%E3%83%80%E3%82%A4%E3%83%A4%E3%83%91%E3%83%BC%E3%82%AF%E6%96%B0%E5%AF%BA2%E4%B8%81%E7%9B%AE/@38.2571091,140.8847913,17z/data=!3m1!4b1!4m5!3m4!1s0x5f8a281bb5435b2d:0x5573b75e98454d9f!8m2!3d38.2571091!4d140.88698?hl=ja"
    },
    {
      key: '2',
      name: '松栄東口第16駐車場',
      place: "仙台市若林区新寺４丁目７−３７",
      price: "200円/1時間",
    },
    {
      key: '3',
      name: 'YMパーキング/Nパーキング/楽々パーキング',
      place: "仙台市宮城野区榴岡４丁目８-５",
      price: "300円/1時間"
    },
    {
      key: '4',
      name: 'M.M.C.東口パーキング',
      place: "仙台市若林区新寺１丁目３−２７",
      price: "300円/1時間"
    },
    {
      key: '5',
      name: 'タイムズ榴岡',
      place: "仙台市宮城野区榴岡４丁目４-７",
      price: "400円/1時間",
      url: "https://www.google.co.jp/maps/place/%E3%82%BF%E3%82%A4%E3%83%A0%E3%82%BA%E6%A6%B4%E5%B2%A1/@38.2599969,140.8831202,17z/data=!4m8!1m2!2m1!1z44K_44Kk44Og44K65qa05bKh!3m4!1s0x5f8a281bccf3ad35:0x41fab56f8ef83275!8m2!3d38.2573818!4d140.8866393?hl=ja&authuser=1"
    },
    {
      key: '6',
      name: '松栄第一パーキング',
      place: "仙台市宮城野区榴岡１丁目２−８",
      price: "400円/1時間",
      url: "https://www.google.co.jp/maps/place/%E6%9D%BE%E6%A0%84%E6%9D%B1%E5%8F%A3%E7%AC%AC%EF%BC%91%E3%83%91%E3%83%BC%E3%82%AD%E3%83%B3%E3%82%B0/@38.259472,140.883225,17z/data=!4m12!1m6!3m5!1s0x5f8a28192318dadf:0xa31e4a0a9186e94b!2z5p2-5qCE5p2x5Y-j56ys77yR44OR44O844Kt44Oz44Kw!8m2!3d38.259472!4d140.885419!3m4!1s0x5f8a28192318dadf:0xa31e4a0a9186e94b!8m2!3d38.259472!4d140.885419?hl=ja&authuser=1"
    },
    {
      key: '7',
      name: 'ヨドバシカメラマルチメディア仙台P1駐車場',
      place: "仙台市宮城野区榴岡１丁目２-１３",
      price: "400円/1時間",
      url: "https://www.google.co.jp/maps/place/%E3%83%A8%E3%83%89%E3%83%90%E3%82%B7%E3%82%AB%E3%83%A1%E3%83%A9%E3%83%9E%E3%83%AB%E3%83%81%E3%83%A1%E3%83%87%E3%82%A3%E3%82%A2%E4%BB%99%E5%8F%B0%EF%BC%B0%EF%BC%91%E9%A7%90%E8%BB%8A%E5%A0%B4/@38.2586039,140.8833261,17z/data=!4m12!1m6!3m5!1s0x5f8a28196dfaf08d:0x33db56fa07963d42!2z44Oo44OJ44OQ44K344Kr44Oh44Op44Oe44Or44OB44Oh44OH44Kj44Ki5LuZ5Y-w77yw77yR6aeQ6LuK5aC0!8m2!3d38.2586039!4d140.8855202!3m4!1s0x5f8a28196dfaf08d:0x33db56fa07963d42!8m2!3d38.2586039!4d140.8855202?hl=ja&authuser=1"
    },
  ]
}

const episode03 = {
  columns: [
    {
      title: '医薬品名',
      dataIndex: 'name',
      key: 'name',
      align: 'left'
    },
    {
      title: '分類',
      dataIndex: 'classification',
      key: 'classification',
      align: 'left'
    },
    {
      title: '急性症状への効果',
      dataIndex: 'short_effect',
      key: 'short_effect',
      align: 'center'
    },
    {
      title: '長期の効果',
      dataIndex: 'long_effect',
      key: 'long_effect',
      align: 'center'
    },
  ],
  dataSource: [
    {
      key: '1',
      name: 'パキシル',
      classification: 'SSRI系抗うつ薬',
      short_effect: "",
      long_effect: "○",
    },
    {
      key: '2',
      name: 'リフレックス',
      classification: '四環系抗うつ薬',
      short_effect: "○",
      long_effect: "",
    },
    {
      key: '3',
      name: 'ソラナックス',
      classification: '抗不安薬',
      short_effect: "○",
      long_effect: "",
    },
  ]
}
const episode04 = {
  columns: [
    {
      title: '分類',
      dataIndex: 'classification',
      key: 'classification',
      align: 'center'
    },
    {
      title: '一般名',
      dataIndex: 'generic',
      key: 'generic',
      align: 'center'
    },
  ],
  dataSource: [
    {
      key: '1',
      classification: '気分安定薬',
      generic: "炭酸リチウム",
    },
    {
      key: '2',
      classification: '気分安定薬',
      generic: "バルプロ酸",
    },
    {
      key: '3',
      classification: '抗精神病薬',
      generic: "オランザピン",
    },
  ]
}

const contact = {
  columns: [
    {
      title: '住所',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: '電話番号',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: 'FAX番号',
      dataIndex: 'fax',
      key: 'fax',
      align: 'right'
    },
    {
      title: 'Eメール',
      dataIndex: 'email',
      key: 'email',
      align: 'right'
    }
  ],
  dataSource: [
    {
      key: '1',
      address: "〒983-0852 仙台市宮城野区榴岡4丁目6-31 サンキョービル2階",
      telephone: "022-369-3691",
      fax: "022-369-3050",
      email: "sendai.mental.clinic@gmail.com"
    },
  ]
}

export { online, premeeting, price, schedule, contact, parking, episode03, episode04, tools}
