import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import styled from 'styled-components'
import LinkButton from '../common/buttons/link'
import { Typography } from 'antd';

const { Title } = Typography;


const FooterImage = ({className}) => {
  const breakpoints = useBreakpoint();
  const data = useStaticQuery(
    graphql`
      query GetFooterImage {
        desktop: file(relativePath: { eq: "footer-image.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    } `
  )
  const imageData = data.desktop.childImageSharp.fluid
  // console.log("imageData@footer", imageData)
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#040e18`}
      style={{
                height: `25vh`,
                width: `100%`,
                backgroundSize: `cover`,
                backgroundPosition: `center`,
                backgroundRepeat: `no-repeat`,
                marginBottom: `10px`,
      }}
    >
      <BlackOverlay>
        <ContentBox>
            {breakpoints.smartphone ? (
              <>
                <div style={{margin: "1rem 1rem"}}>
                  <LinkButton to="/" title="HOME" />
                  <LinkButton to="/about" title="当院について" />
                  <LinkButton to="/online" title="スマホ診療" />
                </div>
                <div>
                  <LinkButton to="/faq" title="よくある質問" />
                  <LinkButton to="/cases" title="架空の症例集" />
                  <LinkButton to="/access" title="アクセス" />
                </div>
              </>
            ) : (
              <div style={{marginBottom: "20px"}}>
                <LinkButton to="/" title="HOME" />
                <LinkButton to="/about" title="当院について" />
                <LinkButton to="/online" title="スマホ診療" />
                <LinkButton to="/faq" title="よくある質問" />
                <LinkButton to="/tools" title="ツール" />
                <LinkButton to="/access" title="アクセス" />
              </div>
            )}

         <Title level={4} style={{color: "#f0e68c", marginTop: "1rem"}}> 仙台東口メンタルクリニック </Title>
           © {new Date().getFullYear()} 立川察理, Built with <a href="https://www.gatsbyjs.org" style={{color: "deepskyblue"}}> Gatsby </a> and <a href="https://aws.amazon.com/jp/amplify/" style={{color: "deepskyblue"}}> AWS Amplify </a>
        </ContentBox>
      </BlackOverlay>
    </BackgroundImage>
  )
}
const BlackOverlay = styled.div`
  display: -webkit-flex;
  display: flex;
  background-color: rgba(0,0,0,0.2);
  height: 100%;
  align-items: center;
  text-align: center;
`

const ContentBox = styled.div`
  margin: 0 auto;
  text-align: center;
  color: white;
`

export default FooterImage
