import React, { useState, createContext } from "react"
import { Row, Col, BackTop } from 'antd';
import Navigation from "./navigation.js"
import LeftSideBar from "./layout/left-sidebar.js"
import RightSideBar from "./layout/right-sidebar.js"
import HeaderImage from "./layout/header-image"
import FooterImage from "./layout/footer-image"

export const CalendarEventContext = createContext()

const backTopStyle = {
  height: 40,
  width: 60,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#1088e9',
  color: '#fff',
  textAlign: 'center',
  fontSize: 14,
}

const Layout = ({ location, title, toc, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  // console.log("toc@Layout", toc)
  // let header

  // if (isRootPath) {
  //   header = (
  //     <h1 className="main-heading">
  //       <Link to="/">{title}</Link>
  //     </h1>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }
  const [events, setEvents] = useState([])
  const state = {
    events,
    setEvents
  }
  // ビューポートをはみ出した要素を検出する
  // useEffect(() => {
  //   window.document.querySelectorAll('*').forEach(el => {
  //     return el.clientWidth > window.document.body.clientWidth ? console.log("overflow", el) : null
  //   })
  // },[])

  return (
    <>
      <BackTop>
        <div style={backTopStyle}>先頭へ</div>
      </BackTop>
      <CalendarEventContext.Provider value={state}>
        <div id="gatsby-focus-wrapper">
          <HeaderImage />
        </div>
        <div className="global-wrapper" data-is-root-path={isRootPath}>
          <Row style={{ margin: '0px'}} >
            <Col xs={24} sm={4} md={24} lg={24} xl={24} >
              <Navigation />
            </Col>
          </Row>
          <Row style={{ margin: 'auto 5%'}} gutter={52} >
            <Col xs={24} sm={4} md={6} lg={6} xl={6} >
              <LeftSideBar  toc={toc} />
            </Col>
            <Col xs={24} sm={4} md={18} lg={12} xl={12} >
              <main>{children}</main>
            </Col>
              <Col xs={24} sm={4} md={0} lg={6} xl={6} >
                <RightSideBar />
              </Col>
          </Row>
        </div>
        <FooterImage />
      </CalendarEventContext.Provider>
    </>
  )
}

export default Layout
