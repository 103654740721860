const EITHER = {
  // ~~~haskell
  // data  Either a b  =  Left a | Right b
  // ~~~
  match: (data, pattern) => {
    return data(pattern);
  },
  left: (value) => (pattern) => {
    return pattern.left(value);
  },
  right: (value) => (pattern) => {
    return pattern.right(value);
  },
  // ~~~haskell
  // instance Monad (Either a b) where
  //   return x = Right x
  //   Right x >>= f = f x
  //   Left x >>= Left x
  // ~~~
  // **Either#unit**
  unit: (value) => {
    return EITHER.right(value);
  },
  // **Either#flatMap**
  flatMap: (instance) => {
    return (transform) => {
      return EITHER.match(instance,{
        right: (value) => {
          return transform(value);
        },
        left: (value) => {
          return EITHER.left(value);
        }
      });
    };
  }
}

export default EITHER;

