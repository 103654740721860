import React  from 'react';

export const Cite = ({references, index}) => {
  const target = references.edges.find((node) => {
    return (node.node.key === index)
  })
  return (
    <span> <a href={target.node.publicurl}> {target.node.title} </a> </span>
  );
}


