import React  from 'react'
import {Calendar as ReactCalendar} from 'react-calendar';
import { getFormatDate } from '../../lib/utils'
import StyledCalendar from './styled-calendar'
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
require('moment/locale/ja');
moment.locale('ja');


const HolidayCalendar = ({month, today, minDate, maxDate, holidays}) => {
  return (
    <>
      <h2 style={{textAlign: "center"}}> {month} </h2>
      <StyledCalendar>
        <ReactCalendar
          style={{margin: 'auto'}}
          locale="ja-JP"
          calendarType="US"
          showNavigation={false}
          value={today}
          minDate={minDate}
          maxDate={maxDate}
          maxDetail='month'
          tileContent={({ date, view }) => ((date.getDay() === 0) || (date.getDay() === 3) || holidays.includes(getFormatDate(date))) ?  <p style={{color: "red"}}>休診</p> : '' }
        />
      </StyledCalendar>
    </>
  )

}

export default HolidayCalendar
