import React from 'react'
import { Link } from "gatsby"
import { Card } from 'antd';
import "antd/dist/antd.css";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import { Typography, Descriptions } from 'antd';
import Phone from './buttons/phone'
import Email from './buttons/email'
import Map from './buttons/map'
import { withBreakpoints } from 'gatsby-plugin-breakpoints';

const { Text } = Typography;


const Contact = ({testid}) => {
  const breakpoints = useBreakpoint();
  return (
    <div data-testid={testid}  className="card">
      <Card title={<Text strong><FontAwesomeIcon  icon={faClinicMedical} /> 基本情報 </Text>}
        size="small" 
        bordered={true} 
        extra={<Link to={"/access"} itemProp="url"> 詳細を見る</Link>} 
        style={{marginTop: 20, backgroundColor: 'cornsilk', border: 3 , borderRadius: 6}}
        headStyle={{backgroundColor: 'wheat', border: 0, borderRadius: 6 }}
       >
        <Descriptions
          bordered={false}
          column={1} 
        >
          <Descriptions.Item label="名称" 
            contentStyle={{justifyContent: "right"}}
          >
            <strong>仙台東口メンタルクリニック</strong>
          </Descriptions.Item>
          <Descriptions.Item label="住所" 
            contentStyle={{justifyContent: "right"}}
          >
            〒983-0852 仙台市
            <br/>
            宮城野区榴岡4丁目
            <br/>
            6-31 
            <br/>
            サンキョービル2階
          </Descriptions.Item>
          <Descriptions.Item label="電話(新患専用)"
            contentStyle={{justifyContent: "right"}}
          >
            050-3355-3691
          </Descriptions.Item>
          <Descriptions.Item label="電話(代表)"
            contentStyle={{justifyContent: "right"}}
          >
            022-369-3691
          </Descriptions.Item>
          <Descriptions.Item label="FAX"
            contentStyle={{justifyContent: "right"}}
          >
            022-369-3050
          </Descriptions.Item>
          <Descriptions.Item label="Email"
            contentStyle={{justifyContent: "right"}}
          >
            <br/>
            sendai.mental.clinic@gmail.com
          </Descriptions.Item>
        </Descriptions>

        {breakpoints.pc ? ( null ) : ( <Phone />)}
        <Email />
        <Map />
      </Card>
  </div>
  )
}

export default withBreakpoints(Contact)
