import React  from 'react';
import { Link } from "gatsby"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck
} from '@fortawesome/free-solid-svg-icons'


export const StyledOl = styled.ol`
  list-style; none;
  margin-left: 14px;
  padding: 0px;
`
export const StyledUl = styled.ul`
  list-style; none;
  margin-top: 6px;
  margin-left: 14px;
  padding: 0px;
`

const StyledLi = styled.li`
  padding: 0px 0px;
  margin-bottom: 4px;
  :first-of-type {
    border-top: none;
  }
`;

const iconStyle = ({fontSize}) => {
  return {
    padding: '8',
    fontSize: fontSize
  };
}

export const ListLink = ({items, depth, testid}) => {
  return (
    <div data-testid={testid} >
      <StyledUl>
        {items.map((item, index) => {
          return Array.isArray(item) 
              ? (<ListLink items={item} depth={depth + 1}/>) 
              : (
                  <StyledLi key={`${depth}-${index}`} >
                    <FontAwesomeIcon icon={faCheck} style={iconStyle({fontSize: "26"})} /> <span> {item} </span>
                  </StyledLi>
              )
        })}
      </StyledUl>
    </div>
  )
}

export const ListItem = ({items, depth, testid}) => {
  return (
    <div data-testid={testid} >
      <StyledUl>
        {items.map((item, index) => {
          return Array.isArray(item) 
              ? (<ListItem items={item} depth={depth + 1}/>) 
              : (
                  <StyledLi key={`${depth}-${index}`} >
                    <FontAwesomeIcon icon={faCheck} style={iconStyle({fontSize: "26"})} /> 
                    <span
                        dangerouslySetInnerHTML={{
                          __html: item
                        }}
                    />
                  </StyledLi>
              )
        })}
      </StyledUl>
    </div>
  )
}



export const ListCase = ({cases, testid}) => {
  return (
    <div data-testid={testid} className="text-center">
      <ol style={{ listStyle: `none` }}>
        {cases.map(article => {
          const title = article.frontmatter.title || article.fields.slug
          return (
            <li key={article.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <span>
                    <Link to={article.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </span>
                </header>
              </article>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export const ListArticle = ({articles, testid}) => {
  return (
    <div data-testid={testid} >
      <ol>
        {articles.map(article => {
          const title = article.frontmatter.title || article.fields.slug
          return (
            <li key={article.fields.slug} style={{margin: 3}}>
              <article
                itemScope
                itemType="http://schema.org/Article"
              >
                  <span>
                    <Link to={article.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </span>
                  <span style={{float: "right"}}>
                    <small>{article.frontmatter.date}</small>
                  </span>
              </article>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

