import React  from 'react';
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'


const HeaderImage = ({className}) => {
  const data = useStaticQuery(
    graphql`
      query GetHeaderImage {
        desktop: file(relativePath: { eq: "sky-clouds.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
    } `
  )
  const imageData = data.desktop.childImageSharp.fluid
  // console.log("imageData", imageData)

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#040e18`}
      style={{
                height: `25vh`,
                width: `100%`,
                backgroundSize: `cover`,
                backgroundPosition: `center`,
                backgroundRepeat: `no-repeat`,
                marginBottom: `10px`,
      }}
    >
      <BlackOverlay>
        <ContentBox>
          <h3 style={{color: "white", marginTop: "4rem"}}> <strong>仙台東口メンタルクリニック</strong> </h3>
        </ContentBox>
      </BlackOverlay>
    </BackgroundImage>
  )
}

const BlackOverlay = styled.div`
  display: -webkit-flex;
  display: flex;
  background-color: rgba(0,0,0,0.2);
  height: 100%;
  align-items: center;
  text-align: center;
`

const ContentBox = styled.div`
  margin: 0 auto;
  text-align: center;
  color: white;
`

export default HeaderImage
