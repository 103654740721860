import React from 'react'
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { iconStyle, buttonStyle } from '../../../styles/custom'


const onSend = (event) => {
  event.preventDefault();
  window.location.href='mailTo:sendai.mental.clinic@gmail.com';
}

const Email = ({testid}) => {
  return (
        <button 
          style={buttonStyle}
          className="button" onClick={onSend}>
          <FontAwesomeIcon style={iconStyle} icon={faEnvelope} /> 
          メール
        </button>
  )
}

export default Email
