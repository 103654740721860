import * as React from "react"
import "antd/dist/antd.css";
import Contact from '../common/contact';
import { useBreakpoint } from 'gatsby-plugin-breakpoints'


const RightSideBar = ({toc}) => {
  const breakpoints = useBreakpoint();
  return (
    <>
      {breakpoints.smartphone ? (
        null 
      ) : (
        <Contact /> 
      )}
    </>
  )
}

export default RightSideBar

