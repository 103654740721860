import React from 'react'
import { Link } from "gatsby"
import { Card } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components"
import { Typography } from 'antd';

const { Text } = Typography;

const TableOfContent = ({items, testid}) => {
  // console.log("items@TableOfContent", items)
  return (
    <div data-testid={testid}  className="site-card-border-less-wrapper">
      <Card 
        title={<Text strong><FontAwesomeIcon  icon={faTable} /> 目次 </Text>}
        size="small" 
        bordered={true} 
        style={{marginTop: 20, backgroundColor: 'ghostwhite', border: 3 , borderRadius: 6}}
        headStyle={{backgroundColor: 'skyblue', border: 0, borderRadius: 6 }}
       >
        <StyledToc>
            <InnerScroll>
              {items &&
                <ul>
                 {
                   items.map(item => {
                      return (
                        <li key={item.title} style={{margin: 3}}>
                         <Link to={item.url}>{item.title}</Link>
                         {/*
                            item.items && (<TableOfContent items={item.items} />)
                          */}
                        </li>
                      )
                    })
                 }
                </ul>
              }
          </InnerScroll>
        </StyledToc>
      </Card>
    </div>
  )
}

export default TableOfContent

const StyledToc = styled.ul`
  width: 20rem;
  left: calc(50% + 400px);
  top: 80px;
  max-height: 30vh;
`
const InnerScroll = styled.div`
  scrollbar-width: thin;
  scrollbar-color: #367ee9 rgba(48, 113, 209, 0.3);
  overflow: hidden auto;
`
