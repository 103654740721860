import React  from 'react';
import DateTime from './common/datetime.js'
import CurrentStatus from './common/current-status.js'
import { Card, Typography } from 'antd';
import "antd/dist/antd.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const { Text } = Typography;

const CurrentInfo = ({test_id}) => {
  return (
    <div data-testid={test_id} className="site-card-border-less-wrapper text-center">
      <Card title={<Text strong><FontAwesomeIcon  icon={faClock} /> 本日の状況 </Text>} size="small" bordered={false} headStyle={{textAlign: "center"}}>
        <DateTime />
        <CurrentStatus />
      </Card>
    </div>
  )
}

export default CurrentInfo;
