import React from 'react'
import { Link } from "gatsby"
import "antd/dist/antd.css";

const buttonStyle = {
  color: '#fff',
  backgroundColor: 'darkgreen',
  border: '0',
  borderRadius: '0.3rem',
  padding: '0.5rem 0.5rem',
  margin: '0.8rem',
  left: '1rem'
}


const LinkButton = ({to, title, testid }) => {
  return (
     <Link style={buttonStyle} to={to}> {title}</Link>
  )
}

export default LinkButton
