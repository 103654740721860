import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Table } from 'antd';
import HolidayCalendar from "../../../../src/components/common/holiday-calendar";
import 'react-calendar/dist/Calendar.css';
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Table,
  HolidayCalendar,
  React
};