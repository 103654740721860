import React, { useState, useEffect, useContext } from 'react';
import EITHER from '../../lib/either'
import {holiday, lunch, outof_service, saturday} from '../../lib/validation'
import { CalendarEventContext } from '../layout'
import moment from 'moment'
require('moment/locale/ja');
moment.locale('ja');

const createMessage = (now, holidays) => {
  const instance = EITHER.flatMap(holiday(now, holidays))(message => {
    return EITHER.flatMap(lunch(now))(message => {
      return EITHER.flatMap(outof_service(now))(message => {
        return EITHER.flatMap(saturday(now))(message => {
          return EITHER.right("現在は、開院中です");
        })
      })
    })
  })
  return EITHER.match(instance, {
    left: (message) => {
      return message;
    },
    right: (message) => {
      return message;
    }
  });
}

const gapiLoad = (gapi, { events, setEvents }, {now, setNow}, {isNewPatientFulfilled, setNewPatinetFulfilled}) => {
  const CALENDAR_ID="sendai.mental.clinic@gmail.com"
  const timeMin=moment().toISOString(),
    timeMax=moment().add(2,"months").toISOString(),
    singleEvents="true";
  const today = moment().format('YYYY-MM-DD')

  gapi.load('client', () => {
    gapi.client.init({
      'apiKey': "AIzaSyBwUrtwp35Ywda9G16CaUpywDisTSIjyQY"
    }).then(function() {
      return gapi.client.request({
        'path': `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?singleEvents=${singleEvents}&timeMin=${timeMin}&timeMax=${timeMax}`,
      })
    }).then((response) => {
      // console.log("response", response)
      setEvents(response.result.items)
      const newPatient = response.result.items.find(event => {
        return (event.start.date === today && event.summary === "新患受付終了")
      })
      // console.log("newPatient", newPatient)
      setNewPatinetFulfilled((newPatient ? true : false))
      // console.log("isNewPatientFulfilled", isNewPatientFulfilled)
    }, function(reason) {
      console.log(reason);
    });
  })

}

const CurrentStatus = ({test_id}) => {
  const [now, setNow] = useState(moment())
  const [isNewPatientFulfilled, setNewPatinetFulfilled] = useState(false)
  const { events, setEvents } = useContext(CalendarEventContext)

  useEffect(() => {
    const  { gapi } =  require('gapi-script'); // gapiをuseEffectの外で定義すると、クラウド上でビルドしたときに windowオブジェクトにアクセスできずにエラーとなる
    setNow(moment())

    gapiLoad(gapi, {events, setEvents}, {now, setNow}, {isNewPatientFulfilled, setNewPatinetFulfilled})

  },[])

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment())
      const  { gapi } =  require('gapi-script'); // gapiをuseEffectの外で定義すると、クラウド上でビルドしたときに windowオブジェクトにアクセスできずにエラーとなる

      gapiLoad(gapi, {events, setEvents}, {now, setNow}, {isNewPatientFulfilled, setNewPatinetFulfilled})
    }, 10000)
    return () => clearInterval(interval)
  },[])

  const holidays = events.filter(event => {
    return event.summary === "休診日"
  }).map(event => { return event.start.date})
  const message = createMessage(now, holidays)

  return (
    <div className="text-center"> 
      <div> {message} </div>
      {isNewPatientFulfilled && (
        <div> 本日の新患受付は終了です </div>
      )}
    </div>
  )
}

export default CurrentStatus;

