import React, { useState, useContext } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import "antd/dist/antd.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import EITHER from '../../lib/either.js'
import { CalendarEventContext } from '../layout'
import moment from 'moment'
require('moment/locale/ja');
moment.locale('ja');
//
// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby");

const modalStyle = {
  backgroundColor: '#fff',
  padding: '5rem 2rem',
  borderRadius: '20%',
  textAlign: 'center'
};

const iconStyle = {
  padding: '10',
  fontSize: '50'
}


const available = (now, holidays) => {
  const weekday = now.isoWeekday()
  const hours = now.hours()
  const weekdayToString = (weekday) => {
    switch (weekday) {
      case 6: return "土曜日"
      case 7: return "日曜日"
      case 3: return "水曜日"
      default: throw new Error()
    }
  }
  // console.log("holidays",  holidays)
  if(holidays.includes(now.format('YYYY-MM-DD'))) {
     return EITHER.left(["本日は、休診日です","新患枠はありません。"])
  } else {
    if(weekday === 6 || weekday === 7 || weekday === 3) { // saturday || sunday || wendsday

      return EITHER.left([`本日は${weekdayToString(weekday)}なので、新患の予約はできません`])
    } else {
      // 診療日
      if((hours >= 10) && (hours < 12)) { // 10時から12時までは新患受付可能
        return EITHER.right([])
      } else {
        return EITHER.left([
          "新患予約の申し込み時間ではありません",
          "新患予約の申し込み時間は、午前10時から正午12時までです"
        ])
      }
    }
  }
}



const Phone = () => {
  const { events } = useContext(CalendarEventContext)
  const [messages, setMessages] = useState([])
  //モーダルの表示状態と切り替える為にState(props)を準備
  const [modalIsOpen, setIsOpen] = useState(false);

  // hoverを処理する
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => { setIsHover(true); };
  const handleMouseLeave = () => { setIsHover(false); };
  const buttonStyle = {
    backgroundColor: isHover ? '#8d3a00' : '#eb6100',
    color: isHover ? '#fff': '#fff2cc',
    border: '0',
    borderRadius: '10px',
    padding: '8px 8px',
    margin: '10px',
    left: '1rem'
  }

  // shouldCloseOnEscやshouldCloseOnOverlayCloseを使う場合に設定が必要
  const closeModal = () => {
    setIsOpen(false)
  }

  const onCall = (event) => {
    const now = moment()
    const holidays = events.filter(event => {
      return event.summary === "休診日"
    }).map(event => { return event.start.date})

    return EITHER.match(available(now, holidays), {
      left: (messages) => { // 電話不可な状況の場合
        setMessages(messages)
        setIsOpen(true)
      },
      right: (_) => {
        const today = now.format('YYYY-MM-DD')
        const newPatientFulfilled = events.find(calendarEvent => {
          return (calendarEvent.start.date === today && calendarEvent.summary === "新患受付終了")
        })
        if(newPatientFulfilled) {
          setMessages(["本日の新患受付は、終了しました"])
          setIsOpen(true)
        } else {
          return ((e) => {
            e.preventDefault();
            window.location.href='tel:050-3355-3691';
          })(event)
        }
      }
    });
  }

  return (
    <>
      <div style={{textAlign:'center'}} className="container">
        <button 
          style={buttonStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="button" onClick={onCall}>
          <FontAwesomeIcon style={iconStyle} icon={faPhone} /> 
          新患予約の電話をかける
        </button>
      </div>
      <Modal 
            className="Modal"
            overlayClassName="Overlay"
            isOpen={modalIsOpen}
            onRequestClose={() => closeModal()}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
       >
          <div style={modalStyle}>
            <StaticImage
              className="tear-drop"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              src="../../images/karsten-winegeart-oU6KZTXhuvk-unsplash.jpg"

              width={120}
              height={120}
              quality={95}
              alt="犬の写真"
            />
            {
              messages.map((message, index) => {
                return (
                  <p key={index}>{message}</p>
                )
              })
            }
            <button 
              style={buttonStyle}
              onClick={closeModal}>
              閉じる
            </button>
          </div>
       </Modal>
    </>
  );
};

export default Phone;
