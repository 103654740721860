import EITHER from './either'

export const holiday = (now, holidays) => {
  if(holidays.includes(now.format('YYYY-MM-DD'))) {
     return EITHER.left("本日は、休診日です")
  } else {
     return EITHER.right("")
  }
}

export const lunch = (now) => {
  const hours = now.hours(),
    minites = now.minutes();
  if(((hours >= 12 && minites > 30) || (hours >= 13)) && hours < 14) {
    return EITHER.left("現在は、昼休みです")
  } else {
    return EITHER.right("")
  }
}

export const outof_service = (now) => {
  const hours = now.hours();
  if(hours < 10 || hours >= 18) {
    return EITHER.left("現在は、診療時間外です")
  } else {
    return EITHER.right("")
  }
}

export const saturday = (now) => {
  const weekday = now.isoWeekday(),
    hours = now.hours();
  if(weekday === 6) { // saturday
    if(hours < 10 || hours >= 16) {
      return EITHER.left("現在は、診療時間外です")
    } else {
      return EITHER.right("")
    }
  } else {
    return EITHER.right("")
  }
}

